import { ListProvider } from './ListProvider';
import { Resolver } from './Resolver';
import { TeamCategory, TeamDivision, TeamGender } from './TeamAttributes';
import { User } from './User';
import { DateRange, Event } from './Event';
import { Stat } from './Stat';
import { RosterEntry } from './RosterEntry';
import { Match } from './Match';
import { Team } from './Team';
import { CompetitionEntry, CompetitionEntryMetadata } from './Competitions/CompetitionEntry';
import { CompetitionAdmin } from './Competitions/CompetitionAdmin';
import { CompetitionEntryRequest } from './Competitions/CompetitionEntryRequest';
import { RegistrationRosterEntry } from './EventRegistration';

export enum RedeemCompetitionInvitationError {
    missingParameters,
    notFound,
    competitionNotFound,
    alreadyRedeemed,
    teamAlreadyJoined,
    serverFailure,
}

export enum SendCompetitionInvitationError {
    failedToGenerateUserToken,
    missingPermission,
    emailAlreadyInvited,
    serverFailure,
}

export interface CompetitionPlayerSummary {
    player: RosterEntry;
    weight: number; // represents the total 'player score' in the match
    statResolvers: Resolver<Stat>[];
}

export interface CompetitionPlayerLeaderboard {
    id: string;
    sortOrder: number;
    title: string;
    players: CompetitionPlayerSummary[];
    displayTextForStatResolvers(statResolvers: Resolver<Stat>[]): Promise<string>;
}

export function isCompetition(candidate): candidate is Competition {
    return (candidate as Competition).event !== undefined;
}

export interface CompetitionDates {
    scheduledRange: DateRange;
    firstScheduledMatchDate?: Date;
    lastCompletedMatchDate?: Date;
}

export interface CompetitionRegistration {
    requestEntryToCompetition: (
        user: User,
        backingTeam: Team,
        metadata: CompetitionEntryMetadata,
        roster: RegistrationRosterEntry[]
    ) => Promise<CompetitionEntryRequest>;
}

export interface Competition {
    id: string;
    attributes: CompetitionAttributes;
    event: Event;

    registration?: CompetitionRegistration;

    competitionDates: CompetitionDates;

    teamProvider: ListProvider<Resolver<CompetitionEntry>>;
    matchProvider: ListProvider<Resolver<Match>>;
    standingsProvider: ListProvider<TeamRecord>;
    leaderboardProvider: ListProvider<CompetitionPlayerLeaderboard>;

    redeemInvitation: (
        user: User,
        competitionEntryInvitationID: string,
        rosterEntries: RegistrationRosterEntry[],
        backingTeam: Team
    ) => Promise<CompetitionEntry>;

    adminForUser(user: User): Promise<CompetitionAdmin>;
}

export interface CompetitionAttributes {
    name: string;

    gender: TeamGender;
    category: TeamCategory;
    division: TeamDivision;

    isIndexed: boolean;
    updateTeamRosterAutomatically: Boolean;

    externalID?: string;
}

export interface TeamRecord {
    team: CompetitionEntry;

    wins: number;
    losses: number;
    draws: number;
}

export function sortCompetitions(left: Competition, right: Competition) {
    const leftCompletedDate = left.competitionDates.lastCompletedMatchDate;
    const rightCompletedDate = right.competitionDates.lastCompletedMatchDate;
    if (leftCompletedDate) {
        if (rightCompletedDate) {
            return leftCompletedDate > rightCompletedDate ? -1 : 1;
        } else {
            return -1;
        }
    } else if (rightCompletedDate) {
        return 1;
    } else if (left.attributes.name < right.attributes.name) {
        return -1;
    } else if (left.attributes.name > right.attributes.name) {
        return 1;
    } else {
        return 0;
    }
}
