import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCalendar, faUser, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

interface NavigationButtonProps {
    title: string;
    icon: IconProp;
    destination: string;
}

const NavigationButtonContent = styled(Link)`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 0px #dee5ef;
    padding: 10px 10px;
`;

function NavigationButton({ title, icon, destination }: NavigationButtonProps) {
    return (
        <>
            <NavigationButtonContent to={destination}>
                <FontAwesomeIcon icon={icon} />
                <NavigationButtonText>{title}</NavigationButtonText>
            </NavigationButtonContent>
        </>
    );
}
const StyledNavigationButton = styled(NavigationButton)`
    min-width: 100px;
    min-height: 60px;
    @media only screen and (max-width: 500px) {
        flex-direction: row;
        min-width: 60px;
    }
`;

const NavigationButtonText = styled.small`
    @media only screen and (max-width: 500px) {
        display: none;
    }
`;

const LogoContainer = styled.div`
    padding: 10px 20px;
    min-width: 100px;
    box-shadow: inset 0px -1px 0px #dee5ef;
    text-align: center;
    @media only screen and (max-width: 500px) {
        padding: 5px 10px;
    }
`;

const NavigationBarStyle = styled.div`
    display: flex;
    padding-left: 10px;
    box-shadow: inset -1px -1px 0px #dee5ef;
    background-color: white;
    flex-direction: column;
    justify-content: flex-start;

    @media only screen and (max-width: 500px) {
        flex-direction: row;
        justify-content: space-around;
        padding-left: 0px;
    }
`;

export default function NavigationBar() {
    if (window.location !== window.parent.location) {
        return <></>;
    }
    return (
        <NavigationBarStyle>
            <LogoContainer>
                <Image
                    className="mx-2"
                    src={`${process.env.PUBLIC_URL}/kap7_logo.png`}
                    height={50}
                />
            </LogoContainer>
            <StyledNavigationButton title="Explore" icon={faSearch} destination="/" />
            <StyledNavigationButton
                title="My Teams"
                icon={faUserFriends}
                destination="/myorganizations"
            />
            <StyledNavigationButton
                title="My Tournaments"
                icon={faCalendar}
                destination="/myevents"
            />
            <StyledNavigationButton title="Account" icon={faUser} destination="/myaccount" />
        </NavigationBarStyle>
    );
}
