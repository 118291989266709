import { ReactNode } from 'react';
import styled from 'styled-components';
import { SportProvider } from '../../models/interfaces/SportProvider';

import TitleBar from './TitleBar';

const ContentView = styled.div`
    flex-grow: 1;
    height: 100%;
    width: 100%;
`;

const DetailViewContainer = styled.div`
    padding-top: 20px;
    padding-left: 20px;
    background-color: #e5e5e5;
    height: 100%;
    width: 100%;
    @media only screen and (max-width: 500px) {
        padding-left: 0px;
    }
`;

const DetailViewContent = styled.div`
    background-color: white;
    height: 100%;
    width: 100%;
`;

interface Props {
    titleView?: string | ReactNode;
    sportProvider: SportProvider;
    accessoryView?: ReactNode;
    children: ReactNode;
}

export default function DetailPageContainer({
    titleView,
    accessoryView,
    sportProvider,
    children,
}: Props) {
    return (
        <ContentView>
            <TitleBar
                title={titleView}
                accessoryView={accessoryView}
                sportProvider={sportProvider}
            />
            <DetailViewContainer>
                <DetailViewContent>{children}</DetailViewContent>
            </DetailViewContainer>
        </ContentView>
    );
}
