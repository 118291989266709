import { Fragment } from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export interface LinkProps {
    section: 'title' | 'badge';
    href: string;
}

export interface LinkPropsArray extends LinkProps {
    // idx of title array or null if link is for badge
    titleIdx: number | null;
}

interface HeaderLinkProps {
    title: string;
    link: LinkProps;
    badge?: string | JSX.Element;
}
const HeaderLink = ({ title, link, badge }: HeaderLinkProps) => {
    if (link.section === 'title')
        return (
            <h4>
                <Link to={link.href}>
                    {title} {badge && <Badge variant="secondary">{badge}</Badge>}
                </Link>
            </h4>
        );

    return (
        <>
            {badge && (
                <Link to={link.href}>
                    <Badge variant="primary">{badge}</Badge>
                </Link>
            )}
            <h4>{title}</h4>
        </>
    );
};

interface HeaderLinkArrayProps {
    title: string[];
    link: LinkPropsArray[];
    badge?: string | JSX.Element;
}

const HeaderLinkArray = ({ title, link, badge }: HeaderLinkArrayProps) => {
    let titleArray = title.map((string) => <span key={string}>{string}</span>);
    let badgeLine = badge && <Badge variant="secondary">{badge}</Badge>;

    link.forEach(({ section, href, titleIdx }) => {
        if (section === 'badge') {
            badgeLine = <Link to={href}>{badgeLine}</Link>;
        } else if (titleIdx !== null) {
            titleArray[titleIdx] = <Link to={href}>{titleArray[titleIdx]}</Link>;
        }
    });

    return (
        <h4>
            {titleArray.map((titleElement, idx) => {
                return (
                    <Fragment key={title[idx]}>
                        {idx !== 0 && ' - '}
                        {titleElement}
                    </Fragment>
                );
            })}{' '}
            {badgeLine && badgeLine}
        </h4>
    );
};

interface HeaderRowProps {
    title: string | string[];
    link?: LinkProps | LinkPropsArray[];
    badge?: string | JSX.Element;
}

const HeaderRow = ({ title, link, badge }: HeaderRowProps) => {
    const titleString = typeof title === 'string' ? title : title?.join(' - ');
    return (
        <Row data-cy="PageHeader-component-header">
            <Col xs="auto">
                {link === undefined ? (
                    <h4>
                        {titleString} {badge && <Badge variant="secondary">{badge}</Badge>}
                    </h4>
                ) : !Array.isArray(link) && !Array.isArray(title) ? (
                    <HeaderLink title={title as string} link={link as LinkProps} badge={badge} />
                ) : (
                    <HeaderLinkArray
                        title={title as string[]}
                        link={link as LinkPropsArray[]}
                        badge={badge}
                    />
                )}
            </Col>
        </Row>
    );
};

interface Props extends HeaderRowProps {
    subTitleArr?: string[];
}
const PageHeader = ({ title, subTitleArr, link, badge }: Props) => {
    if (!subTitleArr) return <HeaderRow title={title} link={link} badge={badge} />;

    return (
        <Row data-cy="PageHeader-component">
            <Col>
                <HeaderRow title={title} link={link} badge={badge} />
                {subTitleArr.map((subTitle) => (
                    <Row key={subTitle}>
                        <Col>
                            <h5>{subTitle}</h5>
                        </Col>
                    </Row>
                ))}
            </Col>
        </Row>
    );
};

export default PageHeader;
