import { Fragment, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row, ListGroup } from 'react-bootstrap';
import StyledListGroupItem, {
    TransitionStyledListGroupItem,
} from '../../../components/containers/StyledListGroupItem';
import { CompetitionLeaders } from '../../interfaces/CompetitionLeadersUI';
import { parseTeamColor } from '../../../helpers/parseTeamColor';
import { useExpandableCard } from '../../../context/useExpandableCardContext';

interface Props {
    leaderType: CompetitionLeaders;
}

const CompetitionLeadersDisplay = ({ leaderType }: Props) => {
    const history = useHistory();
    const { expanded } = useExpandableCard();

    // slice & save the data to render
    const leaderTypeToRender = useMemo(() => {
        if (!expanded) {
            return {
                ...leaderType,
                players: leaderType.players.slice(0, 5),
            };
        } else {
            return {
                ...leaderType,
            };
        }
    }, [leaderType, expanded]);

    return (
        <ListGroup>
            {/* move to render all players then the TransitionStyledListGroupItem is responsible to check if item should be displayed or not */}
            {leaderType.players.map((line) => {
                return (
                    <TransitionStyledListGroupItem
                        key={line.player.id}
                        style={{ minWidth: 200 }}
                        action
                        onClick={() => {
                            const backingPlayer = line.player.backingPlayer;
                            if (backingPlayer) {
                                history.push(`/players/${backingPlayer.id}`);
                            }
                        }}
                        color={parseTeamColor(line.player.team.color)}
                        display={leaderTypeToRender.players.includes(line)}
                    >
                        <Fragment>
                            {/* the below two Row padding top & padding bottom is to offset the padding from matchView.tsx */}
                            <Row className="pt-1">
                                <Col>
                                    #{line.player.capNumber.displayName}{' '}
                                    {line.player.name.firstName} {line.player.name.lastName}{' '}
                                    <b className="pl-1">{line.text}</b>
                                </Col>
                            </Row>
                            <Row className="pb-1">
                                <Col>
                                    <i>{line.player.team.name}</i>
                                </Col>
                            </Row>
                        </Fragment>
                    </TransitionStyledListGroupItem>
                );
            })}
        </ListGroup>
    );
};

export default CompetitionLeadersDisplay;
