import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import TeamRecordView from './TeamRecordView';
import ListView from '../../../components/containers/ListView';
import { Competition } from '../../../models/interfaces/Competition';
import { Card, Row, Col } from 'react-bootstrap';
import { parseTeamColor } from '../../../helpers/parseTeamColor';
import StyledListGroupItem from '../../../components/containers/StyledListGroupItem';

interface Props {
    titleView: ReactElement;
    competition: Competition;
}

export default function CompetitionStandingsList({ titleView, competition }: Props) {
    const history = useHistory();
    return (
        <Row>
            <Col>
                <Card>
                    <Card.Header>{titleView}</Card.Header>
                    <ListView
                        key={competition.id}
                        listProvider={competition.standingsProvider}
                        isFlush={true}
                        listItemTranslator={(teamRecord) => {
                            return (
                                <StyledListGroupItem
                                    key={teamRecord.team.id}
                                    action
                                    onClick={() => {
                                        history.push(
                                            `/competitions/${competition.id}/entries/${teamRecord.team.id}`
                                        );
                                    }}
                                    color={parseTeamColor(teamRecord.team.color)}
                                >
                                    <TeamRecordView teamRecord={teamRecord} />
                                </StyledListGroupItem>
                            );
                        }}
                    />
                </Card>
            </Col>
        </Row>
    );
}
