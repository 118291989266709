import { MatchViewDescriptionProvider } from '../../../interfaces/ViewDescriptions/MatchViewDescription';
import { StatTypeDescription, PerformerTypes, Stat } from '../../../interfaces/Stat';
import {
    WaterPoloGoal,
    WaterPoloShot,
    WaterPoloSave,
    WaterPoloBlock,
    WaterPoloMajorFoul,
    WaterPoloTimeout,
    WaterPoloSteal,
    WaterPoloAssist,
    WaterPoloFullTimeoutSubtype,
    WaterPolo30SecTimeoutSubtype,
    WaterPoloRedCard,
    WaterPoloBrutalitySubtype,
    WaterPoloPlayerMisconduct,
    WaterPoloExclusionSubtype,
    WaterPoloPenaltySubtype,
    WaterPoloSprintWon,
    WaterPoloTeamMisconduct,
    WaterPoloFlagrant,
    WaterPoloOffensiveFoul,
    WaterPoloPenaltyDrawn,
    WaterPoloEjectionDrawn,
} from './WaterPoloStats';
import { WaterPolo } from './WaterPolo';

export class WaterPoloMatchDescriptionProvider implements MatchViewDescriptionProvider {
    fanViewDescription = {
        statDescriptionsToInclude: [
            new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloGoal),
            new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloShot),
            new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloSave),
            new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloBlock),
            new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloMajorFoul),
            new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloSteal),
            new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloAssist),
            new StatTypeDescription(new Set([PerformerTypes.team]), WaterPoloTimeout),
            new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloPlayerMisconduct),
            new StatTypeDescription(new Set([PerformerTypes.team]), WaterPoloTeamMisconduct),
            new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloFlagrant),
            new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloEjectionDrawn),
            new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloPenaltyDrawn),
            new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloOffensiveFoul),
            new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloSprintWon),
        ],

        teamStatDescriptions: [
            {
                title: 'Scoring',
                statDescriptions: [
                    new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloGoal),
                    new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloShot),
                    new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloSave),
                    new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloBlock),
                ],
                textFormatter: (stats, opponentStats) => {
                    const numberOfGoals = stats.filter((stat) => {
                        return stat.description.matchesDescription(
                            new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloGoal)
                        );
                    }).length;
                    const numberOfShots = stats.filter((stat) => {
                        return stat.description.matchesDescription(
                            new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloShot)
                        );
                    }).length;
                    const numberOfOpponentStops = opponentStats.filter((stat) => {
                        return (
                            stat.description.matchesDescription(
                                new StatTypeDescription(
                                    new Set([PerformerTypes.player]),
                                    WaterPoloSave
                                )
                            ) ||
                            stat.description.matchesDescription(
                                new StatTypeDescription(
                                    new Set([PerformerTypes.player]),
                                    WaterPoloBlock
                                )
                            )
                        );
                    }).length;

                    return {
                        title: `${numberOfGoals}/${
                            numberOfGoals + numberOfShots + numberOfOpponentStops
                        }`,
                        isWarning: false,
                    };
                },
            },
            {
                title: 'Save Ratio',
                statDescriptions: [
                    new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloSave),
                    new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloGoal),
                ],
                textFormatter: (teamStats, opponentStats) => {
                    const teamSaveCount = teamStats.filter((teamStat) => {
                        return teamStat.description.matchesDescription(
                            new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloSave)
                        );
                    }).length;
                    const goalsAllowedCount = opponentStats.filter((opponentStat) => {
                        return opponentStat.description.matchesDescription(
                            new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloGoal)
                        );
                    }).length;
                    const totalCount = teamSaveCount + goalsAllowedCount;
                    const text = totalCount ? `${(teamSaveCount / totalCount).toFixed(3)}` : '--';

                    return {
                        title: text,
                        isWarning: false,
                    };
                },
            },
            {
                title: 'Power Plays',
                statDescriptions: [
                    new StatTypeDescription(
                        new Set([PerformerTypes.player]),
                        WaterPoloMajorFoul,
                        WaterPoloExclusionSubtype
                    ),
                    new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloGoal),
                ],
                textFormatter: (teamStats, opponentStats) => {
                    const exclusionStats = opponentStats.filter((stat) => {
                        return stat.description.matchesDescription(
                            new StatTypeDescription(
                                new Set([PerformerTypes.player]),
                                WaterPoloMajorFoul,
                                WaterPoloExclusionSubtype
                            )
                        );
                    });
                    const numberOfConversions = teamStats.filter((goal) => {
                        if (
                            goal.description.dataType.databaseValue !== WaterPoloGoal.databaseValue
                        ) {
                            return false;
                        }
                        return exclusionStats.find((exclusionStat) => {
                            return (
                                exclusionStat.matchSegment.databaseValue ==
                                    goal.matchSegment.databaseValue &&
                                exclusionStat.timestamp - goal.timestamp < 20 &&
                                exclusionStat.timestamp - goal.timestamp >= 0
                            );
                        });
                    }).length;

                    return {
                        title: `${numberOfConversions}/${exclusionStats.length}`,
                        isWarning: false,
                    };
                },
            },
            {
                title: 'Penalties',
                statDescriptions: [
                    new StatTypeDescription(
                        new Set([PerformerTypes.player]),
                        WaterPoloMajorFoul,
                        WaterPoloPenaltySubtype
                    ),
                    new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloGoal),
                ],
                textFormatter: (teamStats, opponentStats) => {
                    const penaltyStats = opponentStats.filter((stat) => {
                        return stat.description.matchesDescription(
                            new StatTypeDescription(
                                new Set([PerformerTypes.player]),
                                WaterPoloMajorFoul,
                                WaterPoloPenaltySubtype
                            )
                        );
                    });
                    const numberOfConversions = teamStats.filter((goal) => {
                        return penaltyStats.find((exclusionStat) => {
                            return (
                                exclusionStat.matchSegment.databaseValue ==
                                    goal.matchSegment.databaseValue &&
                                exclusionStat.timestamp - goal.timestamp < 2 &&
                                exclusionStat.timestamp - goal.timestamp >= 0
                            );
                        });
                    }).length;

                    return {
                        title: `${numberOfConversions}/${penaltyStats.length}`,
                        isWarning: false,
                    };
                },
            },
            {
                title: 'Sprints Won',
                statDescriptions: [
                    new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloSprintWon),
                ],
                textFormatter: (teamStats, opponentStats) => {
                    return {
                        title: `${teamStats.length}`,
                        isWarning: false,
                    };
                },
            },
            {
                title: 'Saves',
                statDescriptions: [
                    new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloSave),
                ],
                textFormatter: (teamStats, opponentStats) => {
                    return {
                        title: `${teamStats.length}`,
                        isWarning: false,
                    };
                },
            },
            {
                title: 'Field Blocks',
                statDescriptions: [
                    new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloBlock),
                ],
                textFormatter: (teamStats, opponentStats) => {
                    return {
                        title: `${teamStats.length}`,
                        isWarning: false,
                    };
                },
            },
        ],

        playerStatDescriptions: [
            {
                id: 'offensiveLeaders',
                title: 'Offensive Leaders',
                weights: [
                    {
                        statDescription: new StatTypeDescription(
                            new Set([PerformerTypes.player]),
                            WaterPoloGoal
                        ),
                        weight: 1,
                    },
                ],
                textFormatter: (stats) => {
                    return {
                        title: `${stats.length}G`,
                        isWarning: false,
                    };
                },
            },
            {
                id: 'defensiveLeaders',
                title: 'Defensive Leaders',
                weights: [
                    {
                        statDescription: new StatTypeDescription(
                            new Set([PerformerTypes.player]),
                            WaterPoloSave
                        ),
                        weight: 1,
                    },
                ],
                textFormatter: (stats) => {
                    return {
                        title: `${stats.length}S`,
                        isWarning: false,
                    };
                },
            },
            // defense
            // saves, blocks, steals
        ],
    };
    officialViewDescription = {
        statDescriptionsToInclude: [
            new StatTypeDescription(new Set([PerformerTypes.team]), WaterPoloTimeout, undefined),
            new StatTypeDescription(
                new Set([PerformerTypes.player]),
                WaterPoloMajorFoul,
                undefined
            ),
            new StatTypeDescription(new Set([PerformerTypes.player]), WaterPoloGoal, undefined),
        ],

        teamStatDescriptions: [
            {
                title: 'Full TOs Used:',
                statDescriptions: [
                    new StatTypeDescription(
                        new Set([PerformerTypes.team]),
                        WaterPoloTimeout,
                        WaterPoloFullTimeoutSubtype
                    ),
                ],
                textFormatter: (stats) => {
                    const matchSegmentText = stats
                        .map((stat) => {
                            return stat.matchSegment.displayName;
                        })
                        .join(', ');

                    return {
                        title: `(${String(stats.length)}) ${matchSegmentText}`,
                        isWarning: false,
                    };
                },
            },
            {
                title: '30 Sec. TOs Used:',
                statDescriptions: [
                    new StatTypeDescription(
                        new Set([PerformerTypes.team]),
                        WaterPoloTimeout,
                        WaterPolo30SecTimeoutSubtype
                    ),
                ],
                textFormatter: (stats) => {
                    const matchSegmentText = stats
                        .map((stat) => {
                            return stat.matchSegment.displayName;
                        })
                        .join(', ');

                    return {
                        title: `(${String(stats.length)}) ${matchSegmentText}`,
                        isWarning: false,
                    };
                },
            },
        ],

        playerStatDescriptions: [
            {
                id: 'majorFouls',
                title: 'Major Fouls',
                weights: [
                    {
                        statDescription: new StatTypeDescription(
                            new Set([PerformerTypes.player]),
                            WaterPoloMajorFoul
                        ),
                        weight: 1,
                    },
                    {
                        statDescription: new StatTypeDescription(
                            new Set([PerformerTypes.player]),
                            WaterPoloPlayerMisconduct
                        ),
                        weight: 1,
                    },
                    {
                        statDescription: new StatTypeDescription(
                            new Set([PerformerTypes.player]),
                            WaterPoloRedCard
                        ),
                        weight: 1,
                    },
                    {
                        statDescription: new StatTypeDescription(
                            new Set([PerformerTypes.player]),
                            WaterPoloFlagrant
                        ),
                        weight: 1,
                    },
                ],
                textFormatter: (stats: Stat[]) => {
                    const isInelgible =
                        stats.length >= 3 ||
                        stats.find((stat) => {
                            return (
                                stat.description.dataType.databaseValue ===
                                    WaterPoloRedCard.databaseValue ||
                                stat.description.dataSubType?.databaseValue ===
                                    WaterPoloBrutalitySubtype.databaseValue ||
                                stat.description.dataType.databaseValue ===
                                    WaterPoloPlayerMisconduct.databaseValue ||
                                stat.description.dataSubType?.databaseValue ===
                                    WaterPoloRedCard.databaseValue ||
                                stat.description.dataType.databaseValue ===
                                    WaterPoloFlagrant.databaseValue
                            );
                        });
                    const statTexts = stats.map((stat) => {
                        return `${stat.matchSegment.displayName}-${stat.abbreviation}`;
                    });
                    const joinedStatText = statTexts.join(', ');
                    const allStatText = `(${stats.length}) ${joinedStatText}`;
                    const isInelgibleText = isInelgible ? 'INELIGIBLE' : '';
                    return {
                        title: isInelgibleText + '\n' + allStatText,
                        isWarning: !!isInelgible,
                    };
                },
            },
            {
                id: 'goals',
                title: 'Goals',
                weights: [
                    {
                        statDescription: new StatTypeDescription(
                            new Set([PerformerTypes.player]),
                            WaterPoloGoal
                        ),
                        weight: 1,
                    },
                ],
                textFormatter: (stats: Stat[]) => {
                    const statText = stats
                        .map((stat) => {
                            return stat.matchSegment.displayName;
                        })
                        .sort()
                        .join(', ');
                    return {
                        title: `(${stats.length}) ${statText}`,
                        isWarning: false,
                    };
                },
            },
        ],
    };

    scoreSheetViewDescription = {
        statDescriptionsToInclude: [],

        teamStatDescriptions: [
            // goals
            // time outs
        ],

        playerStatDescriptions: [
            // Goals
            // Major Fouls
        ],
    };
}
