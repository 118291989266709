import { dateFromFirebaseString, FirebaseItemTranslator } from './FirebaseItemResolver';
import {
    Match,
    MatchParameters,
    MatchSegment,
    MatchSegmentOption,
    MatchStatus,
} from '../../interfaces/Match';
import { Competition } from '../../interfaces/Competition';
import { SportProvider } from '../../interfaces/SportProvider';
import {
    CompetitionData,
    FirebaseBackedMatch,
    FirebaseOfficialVerification,
} from '../FirebaseBackedMatch';

import { MatchEntry } from '../../interfaces/MatchEntry';
import { TeamMatchEntry } from '../sports/waterpolo/LegacySupport/TeamMatchEntry';
import firebase from 'firebase';

export class FirebaseMatchTranslator implements FirebaseItemTranslator<Match> {
    id: string;
    database: firebase.database.Database;
    sportProvider: SportProvider;
    constructor(id: string, database: firebase.database.Database, sportProvider: SportProvider) {
        this.id = id;
        this.database = database;
        this.sportProvider = sportProvider;
    }

    translate(snapshot, onSuccess: (Match) => void, onFailure) {
        // legacy mapping has team and opponent as the keys
        const {
            matchDate,
            hasStarted,
            hasFinished,
            defaultNumberOfTimeouts,
            matchSegmentCountOption,
            defaultMatchSegmentDuration,
        } = snapshot.val();
        var matchSegmentCountOptionObject = MatchSegmentOption.quarters;
        if (matchSegmentCountOption === 'halves') {
            matchSegmentCountOptionObject = MatchSegmentOption.halves;
        }

        const matchVal = snapshot.val();

        const competitionID = matchVal['competition'] || matchVal['league'];
        if (!competitionID) {
            onFailure('Missing competitionID');
            return;
        }
        const lightCapMatchEntry = matchVal['lightCapTeam'];
        const lightCapLegacyID = matchVal['team'];
        const lightCapTeamPromise = lightCapMatchEntry
            ? this.sportProvider
                  .competitionEntryResolver(competitionID, lightCapMatchEntry)
                  .asAPromise()
            : lightCapLegacyID
            ? this.sportProvider
                  .teamResolver(lightCapLegacyID)
                  .asAPromise()
                  .then((resolvedTeam) => {
                      return new TeamMatchEntry(resolvedTeam);
                  })
            : Promise.reject('Missing ids for lightCapTeam');

        const darkCapMatchEntry = matchVal['darkCapTeam'];
        const darkCapLegacyID = matchVal['opponent'];
        const darkCapTeamPromise = darkCapMatchEntry
            ? this.sportProvider
                  .competitionEntryResolver(competitionID, darkCapMatchEntry)
                  .asAPromise()
            : darkCapLegacyID
            ? this.sportProvider
                  .teamResolver(darkCapLegacyID)
                  .asAPromise()
                  .then((resolvedTeam) => {
                      return new TeamMatchEntry(resolvedTeam);
                  })
            : Promise.reject('Missing ids for darkCapTeam');
        const momentDate = dateFromFirebaseString(matchDate);
        if (momentDate) {
            var status = MatchStatus.scheduled;
            if (hasFinished) {
                status = MatchStatus.completed;
            } else if (hasStarted) {
                status = MatchStatus.inProgress;
            }

            var dependentPromises: Promise<any>[] = [lightCapTeamPromise, darkCapTeamPromise];
            const competitionID = matchVal['competition'] || matchVal['league'];
            if (competitionID) {
                const competitionPromise = this.sportProvider
                    .competitionResolver(competitionID)
                    .asAPromise();
                dependentPromises.push(competitionPromise);
            } else {
                onFailure('Missing competition for match');
                return;
            }
            Promise.all(dependentPromises)
                .then((resolvedPromises) => {
                    const lightCapTeam: MatchEntry = resolvedPromises[0];
                    const darkCapTeam: MatchEntry = resolvedPromises[1];
                    const resolvedCompetition: Competition = resolvedPromises[2];

                    var numberOfRegularMatchSegements = 4;
                    if (matchSegmentCountOptionObject === MatchSegmentOption.halves) {
                        numberOfRegularMatchSegements = 2;
                    }
                    var regularTimeMatchSegments: MatchSegment[] = [];
                    for (var i = 0; i < numberOfRegularMatchSegements; i++) {
                        const matchSegment = this.sportProvider.matchSegment(
                            matchSegmentCountOptionObject,
                            i
                        );
                        regularTimeMatchSegments.push(matchSegment);
                    }

                    var overtimeMatchSegments: MatchSegment[] = [];
                    const numberOfOvertimeSegments = 4;
                    for (var i = 0; i < numberOfOvertimeSegments; i++) {
                        const overtimeMatchSegment = this.sportProvider.matchSegment(
                            matchSegmentCountOptionObject,
                            numberOfRegularMatchSegements + i
                        );
                        overtimeMatchSegments.push(overtimeMatchSegment);
                    }
                    const overtimeMatchSegmentDuration = defaultMatchSegmentDuration;

                    const parameters: MatchParameters = {
                        regularTimeMatchSegmentOption: matchSegmentCountOptionObject,
                        regularTimeDescription: {
                            matchSegments: regularTimeMatchSegments,
                            durationInSeconds: defaultMatchSegmentDuration,
                        },
                        overtimeDescription: {
                            matchSegments: overtimeMatchSegments,
                            durationInSeconds: overtimeMatchSegmentDuration,
                        },
                        initialNumberOfTimeouts: defaultNumberOfTimeouts,
                    };
                    const verificationData = snapshot.val()['officialVerification'] || {};
                    const officialName = verificationData['name'];
                    const officialEmail = verificationData['email'];
                    const signatureDataPath = verificationData['signatureReferencePath'];
                    const invalidationDateString = verificationData['invalidationDate'];
                    const invalidationDate =
                        typeof invalidationDateString === 'string'
                            ? dateFromFirebaseString(invalidationDateString)
                            : undefined;
                    const officialData: FirebaseOfficialVerification | undefined =
                        typeof officialName === 'string' &&
                        typeof officialEmail === 'string' &&
                        typeof signatureDataPath === 'string'
                            ? {
                                  officialName: officialName,
                                  email: officialEmail,
                                  invalidationDate: invalidationDate,
                                  firebaseStorageRefPath: signatureDataPath,
                              }
                            : undefined;
                    const competitionData: CompetitionData = {
                        competition: resolvedCompetition,
                        officialVerification: officialData,
                    };

                    const capChanges = matchVal['capChanges'] || {};
                    const externalID = matchVal['externalID'];

                    const match: Match = new FirebaseBackedMatch(
                        this.id,
                        this.sportProvider,
                        this.database,
                        momentDate,
                        lightCapTeam,
                        darkCapTeam,
                        status,
                        parameters,
                        this.sportProvider.matchViewDescriptionProvider.fanViewDescription,
                        this.sportProvider.matchViewDescriptionProvider.officialViewDescription,
                        this.sportProvider.matchViewDescriptionProvider.scoreSheetViewDescription,

                        competitionData,
                        capChanges,
                        externalID
                    );
                    onSuccess(match);
                })
                .catch(() => {
                    onFailure('Failed to resolve match');
                });
        } else {
            onFailure('Failed to resolve match');
        }
    }
}
