import axios from 'axios';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

import { CompetitionMatchManager } from '../../../interfaces/Competitions/CompetitionAdmin';
import { CompetitionEntry } from '../../../interfaces/Competitions/CompetitionEntry';
import { Match, MatchSegmentOption, RemoveMatchResult } from '../../../interfaces/Match';
import { User } from '../../../interfaces/User';
import { FirebaseBackedCompetition } from '../../FirebaseBackedCompetition';

export class FirebaseBackedCompetitionMatchManager implements CompetitionMatchManager {
    user: User;
    competition: FirebaseBackedCompetition;

    constructor(user: User, competition: FirebaseBackedCompetition) {
        this.user = user;
        this.competition = competition;
    }

    createMatch(
        date: Date,
        lightCapTeam: CompetitionEntry,
        darkCapTeam: CompetitionEntry,
        regularTimeMatchSegmentOption: MatchSegmentOption,
        durationForRegularTimeMatchSegments: number,
        defaultNumberOfTimeouts: number,
        externalID?: string
    ) {
        return this.user.fetchVerificationToken().then((token) => {
            const matchID = uuid();
            const dateString = moment(date).format('YYYY-MM-DD HH:mm:ss Z');
            var matchSegmentCountOptionString = 'quarters';
            if (regularTimeMatchSegmentOption === MatchSegmentOption.halves) {
                matchSegmentCountOptionString = 'halves';
            }
            var matchObject = {
                id: matchID,
                matchDate: dateString,
                hasStarted: false,
                lightCapTeam: lightCapTeam.id,
                darkCapTeam: darkCapTeam.id,
                defaultMatchSegmentDuration: durationForRegularTimeMatchSegments,
                matchSegmentCountOption: matchSegmentCountOptionString,
                numberOfTimeouts: defaultNumberOfTimeouts,
                competition: this.competition.id,
            };
            if (externalID) {
                matchObject['externalID'] = externalID;
            }

            const body = {
                matchObject: matchObject,
                userToken: token,
            };
            var apiPath = '/api/competitions/addMatch';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                const status = response.status;
                if (status === 200) {
                    return this.competition.sportProvider.matchResolver(matchID).asAPromise();
                } else {
                    return Promise.reject(
                        `competitionAdmin.createMatch failed with: ${response.statusText}`
                    );
                }
            });
        });
    }

    removeMatch(match: Match) {
        return this.user.fetchVerificationToken().then((token) => {
            const body = {
                userToken: token,
                matchID: match.id,
            };
            var apiPath = '/api/competitions/removeMatch';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                const status = response.status;
                if (status === 200) {
                    return Promise.resolve();
                } else if (status === 406) {
                    return Promise.reject(RemoveMatchResult.parentObjectNotFound);
                } else if (status === 401) {
                    return Promise.reject(RemoveMatchResult.missingPermission);
                } else if (status === 404) {
                    return Promise.reject(RemoveMatchResult.matchNotFound);
                } else {
                    return Promise.reject(status);
                }
            });
        });
    }
}
