import { uuid } from 'uuidv4';
import { SportProvider } from '../interfaces/SportProvider';
import {
    Organization,
    OrganizationAdmin,
    OrganizationInvitation,
} from '../interfaces/Organization';
import { ListProvider } from '../interfaces/ListProvider';
import { Resolver } from '../interfaces/Resolver';
import { User, PublicUser } from '../interfaces/User';
import { FirebaseItemResolver } from './translators/FirebaseItemResolver';
import { FirebaseTeamTranslator } from './translators/FirebaseTeamTranslator';
import { FirebasePublicUserTranslator } from './translators/FirebasePublicUserTranslator';
import { FirebaseBackedListProvider } from '../implementations/FirebaseBackedListProvider';
import { TeamAttributes, TeamColor } from '../interfaces/TeamAttributes';
import { Team } from '../interfaces/Team';
import { AccessGroup } from '../interfaces/AccessGroup';
import { FirebaseBackedTeam } from './FirebaseBackedTeam';
import axios, { AxiosError } from 'axios';
import firebase from 'firebase';

export class FirebaseBackedOrganization implements Organization {
    id: string;
    name: string;
    sportProvider: SportProvider;
    teamProvider: ListProvider<Resolver<Team>>;
    databaseReference: firebase.database.Reference;

    database: firebase.database.Database;

    constructor(
        id: string,
        database: firebase.database.Database,
        sportProvider: SportProvider,
        name: string
    ) {
        this.id = id;
        this.name = name;
        this.sportProvider = sportProvider;
        this.database = database;
        this.databaseReference = database.ref(`organizations/${this.id}`);
        const organizationTeamReference = this.databaseReference.child('accessGroups');
        this.teamProvider = new FirebaseBackedListProvider(
            organizationTeamReference,
            (accessGroupID) => {
                const accessGroupReference = database.ref(`accessGroups/${accessGroupID}`);
                return new FirebaseItemResolver<Team>(accessGroupID, accessGroupReference, {
                    translate: (
                        accessGroupSnapshot,
                        onResolveAccessGroupTeam,
                        onFailedAccessGroupTeam
                    ) => {
                        const accessGroupVal = accessGroupSnapshot.val();
                        const teamID = accessGroupVal.team;
                        if (teamID) {
                            const teamResolver = new FirebaseItemResolver(
                                teamID,
                                database.ref(`teams/${teamID}`),
                                new FirebaseTeamTranslator(teamID, sportProvider, database)
                            );
                            teamResolver.resolve(onResolveAccessGroupTeam, onFailedAccessGroupTeam);
                        } else {
                            onFailedAccessGroupTeam('Failed to load team for access group');
                        }
                    },
                });
            }
        );
    }

    redeemInvitation(token: string, user: User) {
        return user
            .fetchVerificationToken()
            .then((userToken) => {
                const body = {
                    userToken: userToken,
                    invitationID: token,
                };
                // if on dev, use localhost
                // if on staging/prod, use deployment url
                var apiPath = '/api/organizations/redeemInvitation';
                if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                    apiPath = 'http://localhost:3000' + apiPath;
                }
                return axios.post(apiPath, body);
            })
            .then((response) => {
                const status = response.status;
                if (status === 200) {
                    return Promise.resolve();
                } else {
                    return Promise.reject(
                        `organization.redeemInvitation failed with: ${response.statusText}`
                    );
                }
            });
    }

    adminForUser(user: User) {
        const organizationUserRef = this.database.ref(`organizations/${this.id}/users`);
        const adminProvider = new FirebaseBackedListProvider<PublicUser>(
            organizationUserRef,
            (userID) => {
                const userPath = `users/${userID}/public`;
                return new FirebaseItemResolver(
                    userID,
                    this.database.ref(userPath),
                    new FirebasePublicUserTranslator(userID, this.database, this.sportProvider)
                );
            }
        );
        return adminProvider.once().then((userResolvers) => {
            const userResolver = userResolvers.find((resolver) => {
                return resolver.id === user.id;
            });
            if (userResolver) {
                return new FirebaseBackedOrganizationAdmin(user, this);
            } else {
                return Promise.reject('Missing Permission');
            }
        });
    }
}

class FirebaseBackedOrganizationAdmin implements OrganizationAdmin {
    user: User;
    organization: FirebaseBackedOrganization;

    adminProvider: ListProvider<Resolver<PublicUser>>;
    invitationProvider: ListProvider<Resolver<OrganizationInvitation>>;

    constructor(user: User, organization: FirebaseBackedOrganization) {
        this.user = user;
        this.organization = organization;
        this.adminProvider = new FirebaseBackedListProvider(
            organization.database.ref(`organizations/${organization.id}/users`),
            (userID) => {
                return organization.sportProvider.resolverProvider.publicUserResolver(userID);
            }
        );
        this.invitationProvider = new FirebaseBackedListProvider(
            organization.database.ref(`organizations/${organization.id}/invitations`),
            (invitationID) => {
                return organization.sportProvider.resolverProvider.organizationInvitationResolver(
                    invitationID
                );
            }
        );
    }

    updateName(name: string) {
        return this.organization.database
            .ref(`organizations/${this.organization.id}/name`)
            .set(name)
            .then(() => {
                this.organization.name = name;
                return this.organization;
            });
    }

    addTeam(name: string, abbreviation: string, color: TeamColor, teamAttributes: TeamAttributes) {
        const teamID = uuid();

        // create group
        const groupID = uuid();
        const groupData = {
            team: teamID,
            organizationID: this.organization.id,
        };

        // create team
        const teamData = {
            accessGroup: groupID,
            name: name,
            shortName: abbreviation,
            teamTypeV2: teamAttributes.category.databaseValue,
            teamDivisionV2: teamAttributes.division.databaseValue,
            teamGender: teamAttributes.gender.databaseValue,
            hasInitializedRoster: false,
            color: {
                red: color.red,
                green: color.green,
                blue: color.blue,
            },
        };

        return this.organization.database
            .ref(`accessGroups/${groupID}`)
            .set(groupData)
            .then(() => {
                return this.organization.database.ref(`teams/${teamID}`).set(teamData);
            })
            .then(() => {
                return this.organization.database
                    .ref(`organizations/${this.organization.id}/accessGroups/${groupID}`)
                    .set(true);
            })
            .then(() => {
                const accessGroup: AccessGroup = {
                    id: groupID,
                    teamID: groupData.team,
                    organizationID: groupData.organizationID,
                };
                return Promise.resolve(
                    new FirebaseBackedTeam(
                        teamID,
                        this.organization.sportProvider,
                        this.organization.database,
                        accessGroup,
                        name,
                        abbreviation,
                        teamAttributes,
                        color,
                        this.organization.sportProvider.teamDetailMatchStatColumnDescriptions,
                        this.organization.sportProvider.teamDetailPlayerStatColumnDescriptions
                    )
                );
            });
    }

    updateTeam(
        team: Team,
        name: string,
        abbreviation: string,
        color: TeamColor,
        attributes: TeamAttributes
    ): Promise<Team> {
        const teamData = {
            name: name,
            shortName: abbreviation,
            teamTypeV2: attributes.category.databaseValue,
            teamDivisionV2: attributes.division.databaseValue,
            teamGender: attributes.gender.databaseValue,
            hasInitializedRoster: false,
            color: {
                red: color.red,
                green: color.green,
                blue: color.blue,
            },
        };
        return this.organization.database
            .ref(`teams/${team.id}`)
            .update(teamData)
            .then(() => {
                return this.organization.sportProvider.teamResolver(team.id).asAPromise();
            });
    }

    removeTeam(team: Team) {
        return this.organization.database
            .ref(`organizations/${this.organization.id}/accessGroups/${team.accessGroup.id}`)
            .remove();
    }

    sendInvitation(email: string) {
        return this.user
            .fetchVerificationToken()
            .then((token) => {
                const body = {
                    email: email,
                    userToken: token,
                    organizationID: this.organization.id,
                };
                // if on dev, use localhost
                // if on staging/prod, use deployment url
                var apiPath = '/api/organizations/sendInvitation';
                if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                    apiPath = 'http://localhost:3000' + apiPath;
                }
                return axios.post(apiPath, body);
            })
            .then((response) => {
                const status = response.status;
                if (status === 201) {
                    const invitationID = response.data['invitationKey'];
                    return this.organization.sportProvider.resolverProvider
                        .organizationInvitationResolver(invitationID)
                        .asAPromise();
                } else {
                    return Promise.reject(
                        `organizaitonAdmin.send failed with: ${response.statusText}`
                    );
                }
            })
            .catch((error: AxiosError) => {
                if (error.response?.status === 409) {
                    return Promise.reject(
                        `Organization invitation failed due to a duplicate invitation or current admin`
                    );
                } else {
                    return Promise.reject(`Organization invitation failed with: ${error.message}`);
                }
            });
    }

    removeInvitation(invitation: OrganizationInvitation) {
        return this.user
            .fetchVerificationToken()
            .then((token) => {
                const body = {
                    invitationID: invitation.id,
                    userToken: token,
                    organizationID: this.organization.id,
                };
                // if on dev, use localhost
                // if on staging/prod, use deployment url
                var apiPath = '/api/organizations/removeInvitation';
                if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                    apiPath = 'http://localhost:3000' + apiPath;
                }
                return axios.post(apiPath, body);
            })
            .then((response) => {
                const status = response.status;
                if (status === 200) {
                    return Promise.resolve();
                } else {
                    return Promise.reject(
                        `organizaitonAdmin.removeInvitation failed with: ${response.statusText}`
                    );
                }
            })
            .catch((error: AxiosError) => {
                return Promise.reject(error.message);
            });
    }

    leaveOrganization() {
        return this.user
            .fetchVerificationToken()
            .then((token) => {
                const body = {
                    userToken: token,
                    organizationID: this.organization.id,
                };
                // if on dev, use localhost
                // if on staging/prod, use deployment url
                var apiPath = '/api/organizations/leave';
                if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                    apiPath = 'http://localhost:3000' + apiPath;
                }
                return axios.post(apiPath, body);
            })
            .then((response) => {
                const status = response.status;
                if (status === 200) {
                    return Promise.resolve();
                } else {
                    return Promise.reject(
                        `organizaitonAdmin.leave failed with: ${response.statusText}`
                    );
                }
            });
    }
}
