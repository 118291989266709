import {
    Match,
    MatchAdmin,
    MatchParameters,
    MatchStatus,
    MatchSegment,
    MatchEventRecordingGroup,
    MatchViewConfiguration,
    ResultVerification,
} from '../interfaces/Match';
import { SportProvider } from '../interfaces/SportProvider';
import { isMatchEntry, MatchEntry } from '../interfaces/MatchEntry';
import {
    isTableWorker,
    SortedStatProvider,
    Stat,
    StatTypeDescribing,
    TableWorker,
} from '../interfaces/Stat';
import { CapNumber, isRosterEntry, RosterEntry } from '../interfaces/RosterEntry';
import { MatchSegmentStats } from '../interfaces/Stat';
import {
    MatchViewDescription,
    PlayerStatDescription,
    StatWeight,
} from '../interfaces/ViewDescriptions/MatchViewDescription';

import { FirebaseItemResolver } from './translators/FirebaseItemResolver';
import { FirebaseBackedListProvider } from './FirebaseBackedListProvider';
import { FirebaseStatTranslator } from './translators/FirebaseStatTranslator';
import { FirebaseBackedMatchStatProvider } from './FirebaseBackedMatchStatProvider';
import { Competition } from '../interfaces/Competition';
import { User } from '../interfaces/User';
import { uuid } from 'uuidv4';
import axios from 'axios';
import firebase from 'firebase';
import moment from 'moment';
import { EventAdminPermission } from '../interfaces/Event';

export interface FirebaseOfficialVerification {
    officialName: string;
    email: string;
    invalidationDate?: Date;
    firebaseStorageRefPath: string;
}

export interface CompetitionData {
    competition: Competition;
    officialVerification?: FirebaseOfficialVerification;
}

export class FirebaseBackedMatch implements Match {
    id: string;

    sportProvider: SportProvider;
    externalID?: string;

    database: firebase.database.Database;

    date: Date;
    lightCapTeam: MatchEntry;
    darkCapTeam: MatchEntry;

    competition: Competition;

    status: MatchStatus;

    statProvider: SortedStatProvider;

    parameters: MatchParameters;

    firebaseOfficialVerification?: FirebaseOfficialVerification;

    capChanges: { [key: string]: any };

    constructor(
        id: string,
        sportProvider: SportProvider,
        database: firebase.database.Database,
        date: Date,
        lightCapTeam: MatchEntry,
        darkCapTeam: MatchEntry,
        status: MatchStatus,
        parameters: MatchParameters,
        fanViewDescription: MatchViewDescription,
        officialViewDescription: MatchViewDescription,
        scoreSheetDescription: MatchViewDescription,
        competitionData: CompetitionData,
        capChanges: any,
        externalID?: string
    ) {
        this.id = id;
        this.sportProvider = sportProvider;
        this.database = database;

        this.date = date;
        this.lightCapTeam = lightCapTeam;
        this.darkCapTeam = darkCapTeam;
        this.parameters = parameters;
        this.status = status;
        this.competition = competitionData.competition;
        this.firebaseOfficialVerification = competitionData.officialVerification;

        const statListProvider = new FirebaseBackedListProvider<Stat>(
            this.database.ref(`matches/${this.id}/officialStats`),
            (statID) => {
                return new FirebaseItemResolver<Stat>(
                    statID,
                    this.database.ref(`stats/${statID}`),
                    new FirebaseStatTranslator(statID, this.database, this.sportProvider)
                );
            }
        );
        this.statProvider = new FirebaseBackedMatchStatProvider(sportProvider, statListProvider);
        this.fanViewConfiguration = new FirebaseMatchViewConfiguration(this, fanViewDescription);
        this.scoreSheetConfiguration = new FirebaseMatchViewConfiguration(
            this,
            scoreSheetDescription
        );
        this.officialViewConfiguration = new FirebaseMatchViewConfiguration(
            this,
            officialViewDescription
        );

        this.capChanges = capChanges;
        this.externalID = externalID;
    }

    fanViewConfiguration: MatchViewConfiguration;
    scoreSheetConfiguration: MatchViewConfiguration;
    officialViewConfiguration: MatchViewConfiguration;

    scoreForTeam(team: MatchEntry) {
        if (team.id === this.lightCapTeam.id || team.id === this.darkCapTeam.id) {
            const cachedScorePromise = new Promise<number | undefined>((resolve, reject) => {
                if (this.status === MatchStatus.completed) {
                    this.database
                        .ref(`matches/${this.id}/cachedData/scores`)
                        .once('value')
                        .then((scoreSnapshot) => {
                            const scoreVal = scoreSnapshot.val();
                            if (!scoreVal) {
                                var teamScores = {};
                                teamScores[this.lightCapTeam.id] = 0;
                                teamScores[this.darkCapTeam.id] = 0;
                                this.statProvider
                                    .once()
                                    .then((matchStatsList) => {
                                        return matchStatsList.reduce((teamScores, matchStats) => {
                                            const teamScoresForMatchSegment =
                                                matchStats.stats.reduce(
                                                    (teamScoresForMatchSegment, stat) => {
                                                        if (!stat.team) {
                                                            return teamScoresForMatchSegment;
                                                        }
                                                        const currentScoreForTeam =
                                                            teamScoresForMatchSegment.get(
                                                                stat.team.id
                                                            ) || 0;
                                                        var updatedMatchSegmentScores =
                                                            teamScoresForMatchSegment;
                                                        updatedMatchSegmentScores.set(
                                                            stat.team.id,
                                                            currentScoreForTeam + stat.scoreValue
                                                        );
                                                        return updatedMatchSegmentScores;
                                                    },
                                                    new Map<string, number>()
                                                );
                                            var updatedTeamScores = teamScores;
                                            teamScoresForMatchSegment.forEach((score, teamID) => {
                                                updatedTeamScores[teamID] =
                                                    (teamScores[teamID] || 0) + score;
                                            });
                                            return updatedTeamScores;
                                        }, teamScores);
                                    })
                                    .then((teamScores) => {
                                        this.database
                                            .ref(`matches/${this.id}/cachedData/scores`)
                                            .set(teamScores)
                                            .then(() => {
                                                return Promise.resolve(teamScores[team.id] || 0);
                                            });
                                    });
                            } else if (typeof scoreVal[team.id] === 'number') {
                                resolve(scoreVal[team.id]);
                            } else {
                                reject(
                                    `missing cached score for match: ${this.id} team: ${team.id}`
                                );
                            }
                        });
                } else {
                    // caching for in-progress matches is not supported & user will already receive match stat list
                    resolve(undefined);
                }
            });
            return cachedScorePromise;
        } else {
            return Promise.reject('Requesting a score for team that is not in the match');
        }
    }

    capChangeForPlayer(player) {
        const capChangeDatabaseValue = (this.capChanges[player.team.id] || {})[player.id];
        if (capChangeDatabaseValue) {
            const capChange = this.sportProvider.capNumber(capChangeDatabaseValue);
            return Promise.resolve(capChange);
        } else {
            return Promise.resolve(undefined);
        }
    }

    adminForUser(user: User) {
        const competition = this.competition;
        if (competition) {
            const eventID = competition.event.id;
            return this.sportProvider.resolverProvider
                .eventAdminPermissionResolver(eventID, user.id)
                .asAPromise()
                .then(() => {
                    // we don't need to verify permissions be
                    return Promise.resolve(
                        new FirebaseBackedMatchAdmin(
                            this,
                            competition,
                            this.sportProvider.matchRecordingGroupsForAttributes({
                                category: competition.attributes.category,
                                division: competition.attributes.division,
                                gender: competition.attributes.gender,
                            }),
                            this.database,
                            this.sportProvider,
                            user,
                            this.firebaseOfficialVerification
                        )
                    );
                });
        } else {
            return Promise.reject('Match.adminForUser is only supported for competition matches');
        }
    }

    playerSummary(summaryDescription: PlayerStatDescription, matchStats: MatchSegmentStats[]) {
        const matchViewConfiguration = new FirebaseMatchViewConfiguration(this, {
            statDescriptionsToInclude: [],
            teamStatDescriptions: [],
            playerStatDescriptions: [summaryDescription],
        });
        const playerArrays = matchViewConfiguration.playerSummary(
            matchStats,
            summaryDescription.weights
        );
        return {
            title: summaryDescription.title,
            lightCapPlayerArray: playerArrays.lightCapPlayers,
            darkCapPlayerArray: playerArrays.darkCapPlayers,
            displayTextForStats: (stats) => {
                return summaryDescription.textFormatter(stats).title;
            },
        };
    }
}

class FirebaseBackedMatchAdmin implements MatchAdmin {
    match: FirebaseBackedMatch;
    competition: Competition;
    recordingOptionGroups: MatchEventRecordingGroup[];
    database: firebase.database.Database;
    sportProvider: SportProvider;
    user: User;

    firebaseOfficialVerification?: FirebaseOfficialVerification;
    resolvedVerification?: ResultVerification;

    constructor(
        match: FirebaseBackedMatch,
        competition: Competition,
        recordingOptionGroups: MatchEventRecordingGroup[],
        database: firebase.database.Database,
        sportProvider: SportProvider,
        user: User,
        firebaseOfficialVerification?: FirebaseOfficialVerification
    ) {
        this.match = match;
        this.competition = competition;
        this.recordingOptionGroups = recordingOptionGroups;
        this.database = database;
        this.sportProvider = sportProvider;
        this.user = user;
        this.firebaseOfficialVerification = firebaseOfficialVerification;
    }

    start() {
        const ref = this.database.ref(`matches/${this.match.id}/hasStarted`);
        return ref.set(true).then(() => {
            this.match.status = MatchStatus.inProgress;
            return Promise.resolve();
        });
    }

    updateDate(date: Date) {
        return this.user.fetchVerificationToken().then((token) => {
            const dateString = moment(date).format('YYYY-MM-DD HH:mm:ss Z');
            const body = {
                matchID: this.match.id,
                competitionID: this.competition.id,
                userToken: token,
                matchDate: dateString,
            };
            var apiPath = '/api/competitions/updateMatch';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                const status = response.status;
                if (status === 200) {
                    this.match.date = date;
                    return Promise.resolve(this.match);
                } else {
                    return Promise.reject(`The match update failed with status: ${status}`);
                }
            });
        });
    }

    finishMatch(verification?: ResultVerification) {
        const officialSignaturePath = `/matches/${this.match.id}/officialVerification/signature`;
        const officialVerificationObject = verification
            ? {
                  name: verification.officialName,
                  email: verification.email,
                  signatureReferencePath: officialSignaturePath,
              }
            : undefined;
        const completeMatchAPIPromise = this.user.fetchVerificationToken().then((token) => {
            const body = {
                matchID: this.match.id,
                competitionID: this.competition.id,
                userToken: token,
                officialVerification: officialVerificationObject,
            };
            var apiPath = '/api/competitions/completeMatch';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                const status = response.status;
                if (status === 200) {
                    this.match.status = MatchStatus.completed;
                    if (verification) {
                        this.firebaseOfficialVerification = {
                            officialName: verification.officialName,
                            email: verification.email,
                            firebaseStorageRefPath: officialSignaturePath,
                        };
                    }
                    return Promise.resolve();
                } else {
                    return Promise.reject(
                        `matchAdmin.finish() failed with: ${response.statusText}`
                    );
                }
            });
        });
        if (verification) {
            return this.sportProvider.dataProvider
                .uploadDataStringToPath(verification.verificationObject, officialSignaturePath)
                .then(() => {
                    return completeMatchAPIPromise;
                });
        } else {
            return completeMatchAPIPromise;
        }
    }

    updateResultVerification(verification?: ResultVerification) {
        const officialSignaturePath = `/matches/${this.match.id}/officialVerification/signature`;
        const officialVerificationObject = verification
            ? {
                  name: verification.officialName,
                  email: verification.email,
                  signatureReferencePath: officialSignaturePath,
              }
            : undefined;
        const updateResultVerification = this.user.fetchVerificationToken().then((token) => {
            const body = {
                matchID: this.match.id,
                competitionID: this.competition.id,
                userToken: token,
                officialVerification: officialVerificationObject,
            };
            var apiPath = '/api/competitions/updateMatchVerification';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                const status = response.status;
                if (status === 200) {
                    this.match.status = MatchStatus.completed;
                    if (verification) {
                        this.firebaseOfficialVerification = {
                            officialName: verification.officialName,
                            email: verification.email,
                            firebaseStorageRefPath: officialSignaturePath,
                        };
                    }
                    return Promise.resolve();
                } else {
                    return Promise.reject(
                        `matchAdmin.updateResultVerification() failed with: ${response.statusText}`
                    );
                }
            });
        });
        if (verification) {
            return this.sportProvider.dataProvider
                .uploadDataStringToPath(verification.verificationObject, officialSignaturePath)
                .then(() => {
                    return updateResultVerification;
                });
        } else {
            return updateResultVerification;
        }
    }

    recordingOptionForCSVKey(csvKey: string) {
        return this.sportProvider.matchRecordingOptionForCSVKey(csvKey);
    }

    addStat(
        matchSegment: MatchSegment,
        timestamp: number,
        performer: MatchEntry | RosterEntry | TableWorker,
        typeDescription: StatTypeDescribing,
        remarks?: string
    ) {
        return this._addStat(
            matchSegment,
            timestamp,
            performer,
            typeDescription,
            new Date(),
            remarks
        );
    }

    _addStat(
        matchSegment: MatchSegment,
        timestamp: number,
        performer: MatchEntry | RosterEntry | TableWorker,
        typeDescription: StatTypeDescribing,
        creationTime: Date,
        remarks?: string
    ) {
        // create the stat
        const statID = uuid();
        var statDataObject = {
            competition: this.competition.id,
            type: typeDescription.dataType.databaseValue,
            match: this.match.id,
            timestamp: timestamp,
            matchSegment: matchSegment.databaseValue,
            author: this.user.id,
            creationTime: moment(creationTime).format('YYYY-MM-DD HH:mm:ss Z'),
        };
        if (typeDescription.dataSubType) {
            statDataObject['subtype'] = typeDescription.dataSubType.databaseValue;
        }
        if (isRosterEntry(performer)) {
            statDataObject['playerV2'] = performer.id;
            statDataObject['teamV2'] = performer.team.id;
        } else if (isMatchEntry(performer)) {
            statDataObject['teamV2'] = performer.id;
        } else if (isTableWorker(performer)) {
            statDataObject['tableWorker'] = performer.user.id;
        } else {
            return Promise.reject('Failed to generate a perfomer');
        }
        if (remarks) {
            statDataObject['remarks'] = remarks;
        }
        return this.user.fetchVerificationToken().then((token) => {
            const body = {
                matchID: this.match.id,
                competitionID: this.competition.id,
                userToken: token,
                statData: statDataObject,
            };
            var apiPath = '/api/competitions/addMatchStat';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                const status = response.status;
                if (status === 201) {
                    const statID = response.data || '';
                    return this.sportProvider.statResolver(statID).asAPromise();
                } else {
                    return Promise.reject(
                        `matchAdmin.addStat() failed with: ${response.statusText}`
                    );
                }
            });
        });
    }

    displayTextForAddedStat(stat: Stat, existingStats: Stat[]) {
        return this.sportProvider.displayTextForAddedStat(stat, this.match, existingStats);
    }

    updateStat(
        stat: Stat,
        matchSegment: MatchSegment,
        timestamp: number,
        performer: MatchEntry | RosterEntry | TableWorker,
        typeDescription: StatTypeDescribing,
        remarks?: string
    ) {
        if (this.match.status !== MatchStatus.inProgress) {
            // HACK to avoid needing a backend call for updates
            return this.removeStat(stat).then(() => {
                return this._addStat(
                    matchSegment,
                    timestamp,
                    performer,
                    typeDescription,
                    stat.creationTime,
                    remarks
                );
            });
        }
        const statRef = this.database.ref(`stats/${stat.id}`);
        var statDataObject = {
            competition: this.competition.id,
            type: typeDescription.dataType.databaseValue,
            match: this.match.id,
            timestamp: timestamp,
            matchSegment: matchSegment.databaseValue,
            author: this.user.id,
            creationTime: moment(stat.creationTime).format('YYYY-MM-DD HH:mm:ss Z'),
            lastUpdateTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss Z'),
        };
        if (typeDescription.dataSubType) {
            statDataObject['subtype'] = typeDescription.dataSubType.databaseValue;
        }

        if (isRosterEntry(performer)) {
            statDataObject['playerV2'] = performer.id;
            statDataObject['teamV2'] = performer.team.id;
        } else if (isMatchEntry(performer)) {
            statDataObject['teamV2'] = performer.id;
        } else if (isTableWorker(performer)) {
            statDataObject['tableWorker'] = performer.user.id;
        } else {
            return Promise.reject('Failed to generate a perfomer');
        }

        if (remarks) {
            statDataObject['remarks'] = remarks;
        } else {
            statDataObject['remarks'] = null;
        }
        return statRef
            .update(statDataObject)
            .then(() => {
                return new FirebaseItemResolver<Stat>(
                    stat.id,
                    this.database.ref(`stats/${stat.id}`),
                    new FirebaseStatTranslator(stat.id, this.database, this.sportProvider)
                ).asAPromise();
            })
            .catch((error) => {
                return Promise.reject(
                    `matchAdmin.updateStat failed for user: ${this.user.id} with stat: ${stat.id} error: ${error}`
                );
            });
    }

    removeStat(stat: Stat) {
        if (this.match.status === MatchStatus.inProgress) {
            const matchStatRef = this.database.ref(
                `matches/${this.match.id}/officialStats/${stat.id}`
            );
            const statRef = this.database.ref(`stats/${stat.id}`);
            return matchStatRef
                .remove()
                .then(() => {
                    return statRef.remove();
                })
                .catch((error) => {
                    return Promise.reject(
                        `matchAdmin.removeStat failed for user: ${this.user.id} with stat: ${stat.id} error: ${error}`
                    );
                });
        } else {
            return this.user.fetchVerificationToken().then((token) => {
                const body = {
                    matchID: this.match.id,
                    competitionID: this.competition.id,
                    userToken: token,
                    statID: stat.id,
                };
                var apiPath = '/api/competitions/removeMatchStat';
                if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                    apiPath = 'http://localhost:3000' + apiPath;
                }
                return axios.post(apiPath, body).then((response) => {
                    const status = response.status;
                    if (status === 200) {
                        return Promise.resolve();
                    } else {
                        return Promise.reject(
                            `matchAdmin.removeStat() failed with: ${response.statusText}`
                        );
                    }
                });
            });
        }
    }

    removeStats(stats: Stat[]) {
        if (this.match.status === MatchStatus.inProgress) {
            const matchStatRef = this.database.ref(`matches/${this.match.id}/officialStats`);
            const statUpdates = stats.reduce((currentMap, stat) => {
                var updatedMap = currentMap;
                updatedMap[stat.id] = null;
                return updatedMap;
            }, {});
            return matchStatRef
                .update(statUpdates)
                .then(() => {
                    const deleteStatPromises = stats.map((stat) => {
                        const statRef = this.database.ref(`stats/${stat.id}`);
                        return statRef.remove();
                    });
                    return Promise.all(deleteStatPromises);
                })
                .then(() => {
                    return Promise.resolve();
                });
        } else {
            return Promise.all(
                stats.map((stat) => {
                    return this.removeStat(stat);
                })
            ).then(() => {
                return Promise.resolve();
            });
        }
    }

    setCapChangeForPlayer(cap: CapNumber | undefined, player: RosterEntry) {
        const playerCapChangeRef = this.database.ref(
            `matches/${this.match.id}/capChanges/${player.team.id}/${player.id}`
        );
        const players = { ...this.match.capChanges[player.team.id] } || {};
        players[player.id] = cap?.databaseValue;
        this.match.capChanges[player.team.id] = players;
        if (cap) {
            return playerCapChangeRef.set(cap.databaseValue);
        } else {
            return playerCapChangeRef.remove();
        }
    }

    getResultVerificationPromise() {
        const firebaseOfficialVerification = this.firebaseOfficialVerification;
        if (firebaseOfficialVerification) {
            return this.sportProvider.dataProvider
                .downloadDataStringFromPath(firebaseOfficialVerification.firebaseStorageRefPath)
                .then((downloadedData) => {
                    const resultVerification: ResultVerification = {
                        officialName: firebaseOfficialVerification.officialName,
                        email: firebaseOfficialVerification.email,
                        verificationObject: downloadedData,
                        invalidatedDate: firebaseOfficialVerification.invalidationDate,
                    };
                    return Promise.resolve(resultVerification);
                });
        } else {
            return Promise.reject(`No verification found for match: ${this.match.id}`);
        }
    }

    setMatchScore(lightTeamScore: number, darkTeamScore: number) {
        return this.user.fetchVerificationToken().then((token) => {
            const teamScore = {};
            teamScore[this.match.lightCapTeam.id] = lightTeamScore;
            teamScore[this.match.darkCapTeam.id] = darkTeamScore;
            const body = {
                matchID: this.match.id,
                competitionID: this.competition.id,
                userToken: token,
                teamScoresOverride: teamScore,
            };
            var apiPath = '/api/competitions/completeMatch';
            if (process.env.REACT_APP_FIREBASE_KEY === 'development') {
                apiPath = 'http://localhost:3000' + apiPath;
            }
            return axios.post(apiPath, body).then((response) => {
                const status = response.status;
                if (status === 200) {
                    this.match.status = MatchStatus.completed;
                    return Promise.resolve();
                } else {
                    return Promise.reject(
                        `matchAdmin.finish() failed with: ${response.statusText}`
                    );
                }
            });
        });
    }
}

class FirebaseMatchViewConfiguration implements MatchViewConfiguration {
    match: Match;
    configuration: MatchViewDescription;
    constructor(match: Match, configuration: MatchViewDescription) {
        this.match = match;
        this.configuration = configuration;
    }

    shouldDisplayStat(stat: Stat) {
        return !!this.configuration.statDescriptionsToInclude.find((statDesription) => {
            return statDesription.matchesDescription(stat.description);
        });
    }

    teamSummaries(matchStats: MatchSegmentStats[]) {
        return this.configuration.teamStatDescriptions.map((teamSummaryDescription) => {
            const teamStats = this.teamSummary(matchStats, teamSummaryDescription.statDescriptions);
            return {
                title: teamSummaryDescription.title,
                lightCapStats: teamStats.lightCapStats,
                darkCapStats: teamStats.darkCapStats,
                displayTextForStats: (teamStats, opponentStats) => {
                    return teamSummaryDescription.textFormatter(teamStats, opponentStats);
                },
            };
        });
    }

    playerSummaries(matchStats: MatchSegmentStats[]) {
        return this.configuration.playerStatDescriptions.map((playerSummaryDescrption) => {
            const playerArrays = this.playerSummary(matchStats, playerSummaryDescrption.weights);
            return {
                title: playerSummaryDescrption.title,
                lightCapPlayerArray: playerArrays.lightCapPlayers,
                darkCapPlayerArray: playerArrays.darkCapPlayers,
                displayTextForStats: (stats) => {
                    return playerSummaryDescrption.textFormatter(stats);
                },
            };
        });
    }

    teamSummary(matchStats: MatchSegmentStats[], statDescriptions: StatTypeDescribing[]) {
        const match = this.match;
        var lightCapStats: Stat[] = [];
        var darkCapStats: Stat[] = [];

        statDescriptions.forEach((statDescription) => {
            matchStats.forEach((matchSegmentStats) => {
                matchSegmentStats.stats.forEach((stat) => {
                    if (statDescription.matchesDescription(stat.description)) {
                        if (stat.team && stat.team.id === match.lightCapTeam.id) {
                            lightCapStats.push(stat);
                        } else if (stat.team && stat.team.id === match.darkCapTeam.id) {
                            darkCapStats.push(stat);
                        }
                    }
                });
            });
        });

        return {
            lightCapStats: lightCapStats,
            darkCapStats: darkCapStats,
        };
    }

    playerSummary(matchStats: MatchSegmentStats[], statWeights: StatWeight[]) {
        const match = this.match;
        var playerMap = new Map<string, RosterEntry>();
        var lightCapPlayerMap = new Map<string, { weight: number; stats: Stat[] }>();
        var darkCapPlayerMap = new Map<string, { weight: number; stats: Stat[] }>();
        statWeights.forEach((statPairWeight) => {
            matchStats.forEach((matchSegmentStats) => {
                matchSegmentStats.stats.forEach((stat) => {
                    if (
                        statPairWeight.statDescription.matchesDescription(stat.description) &&
                        stat.player
                    ) {
                        const playerID = stat.player.id;
                        playerMap.set(playerID, stat.player);
                        if (stat.player.team.id === match.lightCapTeam.id) {
                            var lightCapPlayerWeight = lightCapPlayerMap.get(playerID);
                            if (!lightCapPlayerWeight) {
                                lightCapPlayerWeight = { weight: 0, stats: [] };
                            }
                            const updatedWeight =
                                lightCapPlayerWeight.weight + statPairWeight.weight;
                            var lightCapPlayerStats = lightCapPlayerWeight.stats;
                            lightCapPlayerStats.push(stat);
                            lightCapPlayerMap.set(playerID, {
                                weight: updatedWeight,
                                stats: lightCapPlayerStats,
                            });
                        } else if (stat.team && stat.team.id === match.darkCapTeam.id) {
                            var darkCapPlayerWeight = darkCapPlayerMap.get(playerID);
                            if (!darkCapPlayerWeight) {
                                darkCapPlayerWeight = { weight: 0, stats: [] };
                            }
                            const updatedWeight =
                                darkCapPlayerWeight.weight + statPairWeight.weight;
                            var darkCapPlayerStats = darkCapPlayerWeight.stats;
                            darkCapPlayerStats.push(stat);
                            darkCapPlayerMap.set(playerID, {
                                weight: updatedWeight,
                                stats: darkCapPlayerStats,
                            });
                        }
                    }
                });
            });
        });
        return {
            lightCapPlayers: this.transformPlayerWeightMap(playerMap, lightCapPlayerMap),
            darkCapPlayers: this.transformPlayerWeightMap(playerMap, darkCapPlayerMap),
        };
    }

    transformPlayerWeightMap(
        playerMap: Map<string, RosterEntry>,
        playerWeightMap: Map<string, { weight: number; stats: Stat[] }>
    ) {
        return Array.from(playerWeightMap.keys())
            .reduce((currentPlayerArray, playerID) => {
                const player = playerMap.get(playerID);
                const playerWeight = playerWeightMap.get(playerID);
                if (!player || !playerWeight) {
                    return currentPlayerArray;
                } else {
                    const entry = {
                        player: player,
                        weight: playerWeight.weight,
                        stats: playerWeight.stats,
                    };
                    var updatedPlayerArray = currentPlayerArray;
                    updatedPlayerArray.push(entry);
                    return updatedPlayerArray;
                }
            }, new Array<{ player: RosterEntry; weight: number; stats: Stat[] }>())
            .sort((left, right) => {
                return right.weight - left.weight;
            });
    }
}
