import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { User } from '../../models/interfaces/User';
import { RosterEntry } from '../../models/interfaces/RosterEntry';
import { Competition } from '../../models/interfaces/Competition';
import { CompetitionAdmin } from '../../models/interfaces/Competitions/CompetitionAdmin';
import CompetitionStandingsList from './modules/CompetitionStandingsList';
import CompetitionLeadersView from './modules/CompetitionLeadersView';
import CompletedMatchesView from './CompletedMatchesView';
import CreateMatchCrudModal from '../matches/CreateMatchCrudModal';
import UpdateMatchCrudModal from '../matches/UpdateMatchCrudModal';
import { isMatch, MatchSegmentOption } from '../../models/interfaces/Match';
import UpcomingMatchesView from '../matches/UpcomingMatchesView';
import { SportProvider } from '../../models/interfaces/SportProvider';
import PageHeader from '../../components/containers/PageHeader';
import useListProviderToArray from '../../helpers/useListProviderToArray';
import { Link } from 'react-router-dom';

interface Props {
    competition: Competition;
    sportProvider: SportProvider;
    user?: User;
}

export default function CompetitionDetailPage({ competition, sportProvider, user }: Props) {
    const [competitionAdmin, setCompetitionAdmin] = useState<CompetitionAdmin | undefined>(
        undefined
    );
    const [showCreateMatchModal, setShowCreateMatchModal] = useState(false);
    const [competitionLeaders, setCompetitionLeaders] = useState<
        { title: string; players: { player: RosterEntry; text: string }[] }[] | undefined
    >(undefined);

    const matches = useListProviderToArray({
        provider: competition.matchProvider,
        isType: isMatch,
    });

    useEffect(() => {
        if (user) {
            competition.adminForUser(user).then((competitionAdmin) => {
                setCompetitionAdmin(competitionAdmin);
            });
        }
    }, [competition, user]);

    useEffect(() => {
        competition.leaderboardProvider
            .once()
            .then((leaderGroups) => {
                const leaderGroupPromises = leaderGroups.map((leaderGroup) => {
                    const playerSummaryPromises = leaderGroup.players.map((playerSummary) => {
                        return leaderGroup
                            .displayTextForStatResolvers(playerSummary.statResolvers)
                            .then((playerText) => {
                                return {
                                    player: playerSummary.player,
                                    text: playerText,
                                };
                            });
                    });
                    return Promise.allSettled(playerSummaryPromises)
                        .then((playerSummaryResults) => {
                            return playerSummaryResults.flatMap((playerSummaryResult) => {
                                if (playerSummaryResult.status === 'fulfilled') {
                                    return [playerSummaryResult.value];
                                } else {
                                    return [];
                                }
                            });
                        })
                        .then((inflatedPlayerSummaries) => {
                            return {
                                title: leaderGroup.title,
                                sortOrder: leaderGroup.sortOrder,
                                players: inflatedPlayerSummaries,
                            };
                        });
                });
                return Promise.all(leaderGroupPromises);
            })
            .then((inflatedLeaderGroups) => {
                setCompetitionLeaders(
                    inflatedLeaderGroups.sort((left, right) => {
                        return left.sortOrder - right.sortOrder;
                    })
                );
            });
    }, [competition, sportProvider]);

    const subTitle = sportProvider.displayTextForTeamOrCompetitionAttributes(
        competition.attributes
    );
    const noCompetitionLeaders = competitionLeaders && competitionLeaders.length > 0;

    const badge =
        competition.attributes.name !== competition.event?.attributes.name
            ? competition.event?.attributes.name
            : undefined;

    const matchManager = competitionAdmin?.matchManager;
    return (
        <Container className="pt-2">
            <Row className="justify-content-between">
                <Col xs="auto">
                    <PageHeader
                        title={competition.attributes.name}
                        subTitleArr={[subTitle]}
                        badge={badge}
                        link={{
                            section: badge ? 'badge' : 'title',
                            href: `/events/${competition.event?.id}`,
                        }}
                    />
                </Col>
                {competitionAdmin && (
                    <Col xs="auto">
                        <Link to={`/competitions/${competition.id}/admin`}>
                            <Button>Competition Management Page</Button>
                        </Link>
                    </Col>
                )}
            </Row>

            <UpcomingMatchesView
                matches={matches}
                accessoryView={
                    competitionAdmin ? (
                        <Button
                            size="sm"
                            onClick={() => setShowCreateMatchModal(!showCreateMatchModal)}
                        >
                            Add
                        </Button>
                    ) : undefined
                }
                showCompetition={false}
            />
            <Row>
                <Col>
                    <Row
                        className={`pt-4 justify-content-${
                            noCompetitionLeaders ? 'between' : 'around'
                        }`}
                    >
                        <Col className="mb-4" xs={12} sm={6} lg={4}>
                            <CompetitionStandingsList
                                titleView={<>Standings</>}
                                competition={competition}
                            />
                        </Col>
                        <Col xs={12} sm={6} lg={4}>
                            <CompletedMatchesView matches={matches} showCompetition={false} />
                        </Col>
                        {noCompetitionLeaders && (
                            <Col xs={12} sm={6} lg={4}>
                                <CompetitionLeadersView competitionLeaders={competitionLeaders} />
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>

            {matchManager && (
                <CreateMatchCrudModal
                    key={'competitions/' + competition.id + '/createMatch'}
                    shouldShow={showCreateMatchModal}
                    teamProvider={competition.teamProvider}
                    title="Create Match"
                    submitBtnText="Create Match"
                    submitFunction={(
                        matchDate,
                        lightColoredTeam,
                        darkColoredTeam,
                        onSuccess,
                        onFailure
                    ) => {
                        matchManager
                            .createMatch(
                                matchDate,
                                lightColoredTeam,
                                darkColoredTeam,
                                MatchSegmentOption.quarters,
                                480,
                                4
                            )
                            .then(() => {
                                onSuccess();
                            })
                            .catch((error) => {
                                onFailure(error);
                            });
                    }}
                    hideModal={() => {
                        setShowCreateMatchModal(false);
                    }}
                />
            )}
        </Container>
    );
}
