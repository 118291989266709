import React, { useState } from 'react';
import { Form, Button, Spinner, Container, Row, Col, Card } from 'react-bootstrap';
import { TeamAttributes } from '../../models/interfaces/TeamAttributes';
import styled from 'styled-components';
import { Event } from '../../models/interfaces/Event';
import { CompetitionAttributes } from '../../models/interfaces/Competition';
import { SportProvider } from '../../models/interfaces/SportProvider';
import TeamAttributesPicker from '../teams/TeamAttributesPicker';
import { Formik } from 'formik';
import * as yup from 'yup';
import { TeamAttributesErrors, TeamAttributesTouched } from '../interfaces/TeamUI';

type Props = {
    parentEvent: Event;
    initialAttibutes?: CompetitionAttributes;
    sportProvider: SportProvider;
    onSubmitCompetitionAttributes: (CompetitionAttributes) => Promise<void>;
    onHide: () => void;
};

const ButtonBox = styled.div`
    display: flex;
    justify-content: space-between;
`;

const schema = yup.object({
    competitionName: yup.string(),
    teamAttributes: yup.object({
        gender: yup.object({}).required('Team Gender is required'),
        division: yup.object({}).required('Team Division is required'),
        category: yup.object({}).required('Team Category is required'),
    }),
});
interface formValues {
    competitionName: string | undefined;
    teamAttributes: TeamAttributes | undefined;
    isIndexed: boolean;
}

export default function CreateCompetition({
    parentEvent,
    initialAttibutes,
    sportProvider,
    onSubmitCompetitionAttributes,
    onHide,
}: Props) {
    const initialValues: formValues = {
        competitionName: initialAttibutes?.name,
        teamAttributes: initialAttibutes
            ? {
                  gender: initialAttibutes.gender,
                  category: initialAttibutes.category,
                  division: initialAttibutes.division,
              }
            : undefined,
        isIndexed: initialAttibutes ? initialAttibutes.isIndexed : false,
    };

    const [hasPendingAction, setHasPendingAction] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    return (
        <Row className="justify-content-center my-2">
            <Col sm={6} md={6} lg={6}>
                <Formik
                    validationSchema={schema}
                    initialValues={initialValues}
                    onSubmit={({ competitionName, teamAttributes, isIndexed }) => {
                        if (competitionName && teamAttributes) {
                            const attributes: CompetitionAttributes = {
                                name: competitionName,
                                category: teamAttributes.category,
                                division: teamAttributes.division,
                                gender: teamAttributes.gender,
                                isIndexed: isIndexed,
                                updateTeamRosterAutomatically:
                                    teamAttributes.category.allowsRosterPropagation,
                            };
                            setHasPendingAction(true);
                            onSubmitCompetitionAttributes(attributes).finally(() => {
                                setHasPendingAction(false);
                            });
                        }
                    }}
                >
                    {({ values, handleChange, handleSubmit, setFieldValue, errors, touched }) => (
                        <Form
                            noValidate
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                        >
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                name="competitionName"
                                value={values.competitionName}
                                onChange={handleChange}
                            />
                            <hr />
                            <Form.Check
                                name="isIndexed"
                                checked={values.isIndexed}
                                onChange={handleChange}
                                label="Visible on homepage"
                            />
                            <hr />
                            <TeamAttributesPicker
                                sportProvider={sportProvider}
                                onUpdateTeamAttributes={(updatedTeamAttributes) => {
                                    setFieldValue('teamAttributes', updatedTeamAttributes);
                                }}
                                errors={
                                    (errors.teamAttributes as TeamAttributesErrors) || undefined
                                }
                                touched={
                                    (touched.teamAttributes as TeamAttributesTouched) || undefined
                                }
                                initialAttributes={initialValues.teamAttributes}
                            />
                            <ButtonBox>
                                {!hasPendingAction && (
                                    <Button variant="secondary" onClick={onHide}>
                                        Cancel
                                    </Button>
                                )}
                                {!hasPendingAction && (
                                    <Button type="submit" variant="primary">
                                        {initialAttibutes ? 'Update' : 'Create'}
                                    </Button>
                                )}
                                {hasPendingAction && (
                                    <Button variant="primary" disabled>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button>
                                )}
                            </ButtonBox>
                            {errorMessage && <span>{errorMessage}</span>}
                        </Form>
                    )}
                </Formik>
            </Col>
        </Row>
    );
}
