import { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Competition } from './../../models/interfaces/Competition';
import { Card, Col, Row, Button, Spinner, Modal, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { EventAdmin } from '../../models/interfaces/Event';
import CreateCompetition from '../competitions/CreateCompetition';
import { SportProvider } from '../../models/interfaces/SportProvider';
import useListProviderToArray from '../../helpers/useListProviderToArray';
import { isMatch, MatchStatus } from '../../models/interfaces/Match';
import UpcomingMatchesView from '../matches/UpcomingMatchesView';
import CompletedMatchesView from '../competitions/CompletedMatchesView';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
    competition: Competition;
    sportProvider: SportProvider;
    showEvent: boolean;
    showMatches: boolean;
    eventAdmin?: EventAdmin;
    onUpdateCompetition: (Competition) => void;
}

export default function CompetitionCard({
    competition,
    sportProvider,
    showEvent,
    showMatches,
    eventAdmin,
    onUpdateCompetition,
}: Props) {
    const history = useHistory();

    const [confirmDelete, setConfirmDelete] = useState(false);
    const [hasPendingAction, setHasPendingAction] = useState(false);

    const [showEditingModal, setShowEditingModal] = useState(false);
    const matches = useListProviderToArray({
        provider: competition.matchProvider,
        isType: isMatch,
    });

    const hasUpcomingMatches = useMemo(() => {
        return matches?.some((match) => match.status !== MatchStatus.completed);
    }, [matches]);

    const hasCompletedMatches = useMemo(() => {
        return matches?.some((match) => match.status === MatchStatus.completed);
    }, [matches]);

    return (
        <>
            <Card data-cy={`${competition.id}-competition-card`}>
                <Card.Header>
                    <Row className="align-items-center justify-content-between">
                        <Col xs={12} sm={12} md={eventAdmin ? 6 : 12}>
                            {showEvent &&
                                competition.attributes.name !==
                                    competition.event.attributes.name && (
                                    <Link to={`/events/${competition.event.id}`}>
                                        <Badge variant="primary" className="mb-1">
                                            <small>{competition.event.attributes.name}</small>
                                        </Badge>
                                    </Link>
                                )}
                            <Link
                                to={`/competitions/${competition.id}`}
                                style={{ textDecoration: 'none' }}
                            >
                                <h4>{competition.attributes.name}</h4>
                                <p className="text-muted mb-0">
                                    {` ${sportProvider.displayTextForTeamOrCompetitionAttributes(
                                        competition.attributes
                                    )} `}
                                    {<FontAwesomeIcon icon={faChevronRight as IconProp} />}
                                </p>
                            </Link>
                        </Col>
                        {eventAdmin?.competitionEditor ? (
                            hasPendingAction ? (
                                <Spinner
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            ) : (
                                <Col xs={12} sm={12} md={6}>
                                    <Row className="my-1 justify-content-end">
                                        {confirmDelete ? (
                                            <Button
                                                variant="outline-danger"
                                                onClick={() => {
                                                    setHasPendingAction(true);
                                                    eventAdmin?.competitionEditor
                                                        ?.removeCompetition(competition)
                                                        .finally(() => {
                                                            setHasPendingAction(false);
                                                        });
                                                }}
                                            >
                                                Confirm Delete
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="outline-danger"
                                                onClick={() => {
                                                    setConfirmDelete(true);
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        )}
                                    </Row>
                                    <Row className="my-1 justify-content-end">
                                        {confirmDelete ? (
                                            <Button
                                                variant="outline-primary"
                                                onClick={() => {
                                                    setConfirmDelete(false);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="outline-primary"
                                                onClick={() => {
                                                    setShowEditingModal(true);
                                                }}
                                            >
                                                Edit
                                            </Button>
                                        )}
                                    </Row>
                                </Col>
                            )
                        ) : (
                            <></>
                        )}
                    </Row>
                </Card.Header>
                {showMatches &&
                    (matches ? (
                        matches.length > 0 ? (
                            <>
                                {hasUpcomingMatches && (
                                    <UpcomingMatchesView
                                        matches={matches}
                                        showCompetition={false}
                                        compressed={true}
                                    />
                                )}
                                {hasCompletedMatches && (
                                    <CompletedMatchesView
                                        matches={matches}
                                        showCompetition={false}
                                    />
                                )}
                            </>
                        ) : (
                            <Card.Body>No matches created</Card.Body>
                        )
                    ) : (
                        <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
                    ))}
            </Card>
            {eventAdmin?.competitionEditor && (
                <Modal show={showEditingModal} onHide={() => setShowEditingModal(false)}>
                    <CreateCompetition
                        sportProvider={sportProvider}
                        initialAttibutes={competition.attributes}
                        parentEvent={competition.event}
                        onSubmitCompetitionAttributes={(competitionAttributes) => {
                            return eventAdmin.competitionEditor
                                ? eventAdmin.competitionEditor
                                      .updateCompetition(competition, competitionAttributes)
                                      .then((updatedCompetition) => {
                                          onUpdateCompetition(updatedCompetition);
                                          setShowEditingModal(false);
                                      })
                                : Promise.reject('Missing admin permissions');
                        }}
                        onHide={() => {
                            setShowEditingModal(false);
                        }}
                    />
                </Modal>
            )}
        </>
    );
}
