import { MatchEntry } from './MatchEntry';
import { Player } from './Player';

export interface CapNumber {
    databaseValue: string;
    displayName: string;
    sortValue: number;
}

export interface Position {
    databaseValue: string;
    displayName: string;
}

export interface Name {
    firstName: string;
    lastName: string;
}

export interface PlayerMetadata {
    id: string;
    name: Name;
    capNumber: CapNumber;
    position: Position;
    isArchived: boolean;
}

export function isRosterEntry(candidate: RosterEntry | any): candidate is RosterEntry {
    return (
        (candidate as RosterEntry).capNumber !== undefined &&
        (candidate as Player).matchStatProvider === undefined
    );
}

export interface RosterEntry extends PlayerMetadata {
    team: MatchEntry;
    backingPlayer?: Player;
}
