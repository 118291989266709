import { createContext, ReactChild, useState, useCallback, useContext } from 'react';
import { Card, Button } from 'react-bootstrap';
import styled from 'styled-components';

const StyledHeaderLink = styled(Button)`
    padding: 0 1rem;
    vertical-align: baseline;
    padding: 0;
    font-size: 0.9rem;

    &:focus {
        box-shadow: none;
    }
`;
const StyledHeader = styled(Card.Header)`
    display: flex;
    justify-content: space-between;
`;

interface IExpandableCardContext {
    expanded: boolean;
}

const ExpandableCardContext = createContext<IExpandableCardContext>({
    expanded: false,
});

interface Props {
    headerText: string;
    buttonDisplayOptions: { expanded: string; collapsed: string };
    //showExpand is used to hide toggles and buttons if list is too small
    showExpand: boolean;
    children: ReactChild;
}

// to gain access to context this provider needs to be wrap the component which wants to use the state. e.g. using the provider to wrap all items in a component won't give you access to the context you need to wrap the component itself to gain access
export const ExpandableCardContextProvider = ({
    headerText,
    buttonDisplayOptions,
    showExpand,
    children,
}: Props) => {
    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = useCallback(() => {
        setExpanded((boolean) => !boolean);
    }, []);

    return (
        <ExpandableCardContext.Provider value={{ expanded }}>
            <Card>
                <StyledHeader>
                    {headerText}
                    {showExpand && (
                        <StyledHeaderLink variant="link" onClick={toggleExpanded}>
                            {expanded ? 'Collapse' : 'Show All'}
                        </StyledHeaderLink>
                    )}
                </StyledHeader>
                {/* child element is to be responsible for expanding and collapsing their data. this includes adding any transition effects*/}
                {children}
                {showExpand && (
                    <Button onClick={toggleExpanded}>
                        {expanded ? buttonDisplayOptions.expanded : buttonDisplayOptions.collapsed}
                    </Button>
                )}
            </Card>
        </ExpandableCardContext.Provider>
    );
};

export function useExpandableCard() {
    return useContext(ExpandableCardContext);
}
