import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import {
    TeamAttributes,
    TeamCategory,
    TeamDivision,
    TeamGender,
} from '../../models/interfaces/TeamAttributes';
import { SportProvider } from '../../models/interfaces/SportProvider';
import { TeamAttributesErrors, TeamAttributesTouched } from '../interfaces/TeamUI';

interface Props {
    sportProvider: SportProvider;
    onUpdateTeamAttributes(teamAttributes?: TeamAttributes): void;
    errors?: TeamAttributesErrors;
    touched?: TeamAttributesTouched;
    initialAttributes?: TeamAttributes;
}

export default function TeamAttributesPicker({
    sportProvider,
    onUpdateTeamAttributes,
    errors,
    touched,
    initialAttributes,
}: Props) {
    const teamCategories = sportProvider.teamCategories;
    const teamGenders = sportProvider.teamGenders;

    const [selectedTeamCategory, setSelectedTeamCategory] = useState<TeamCategory>(
        initialAttributes ? initialAttributes.category : teamCategories[0]
    );
    const [selectedTeamDivision, setSelectedTeamDivision] = useState<TeamDivision>(
        initialAttributes ? initialAttributes.division : selectedTeamCategory.divisions[0]
    );
    const [selectedGender, setSelectedGender] = useState<TeamGender | null>(
        initialAttributes ? initialAttributes.gender : null
    );

    return (
        <>
            <Form.Group
                className="text-center"
                onChange={(event: any) => {
                    const key = event.target.id;
                    const updatedSelectedGender = teamGenders.find((teamGender) => {
                        return teamGender.databaseValue === key;
                    });
                    if (updatedSelectedGender) {
                        setSelectedGender(updatedSelectedGender);
                        onUpdateTeamAttributes({
                            category: selectedTeamCategory,
                            division: selectedTeamDivision,
                            gender: updatedSelectedGender,
                        });
                    }
                }}
            >
                {teamGenders.map((teamGender) => {
                    return (
                        <Form.Check
                            required
                            inline
                            type="radio"
                            id={teamGender.databaseValue}
                            key={teamGender.databaseValue}
                            name="gender"
                            label={teamGender.name}
                            checked={selectedGender?.databaseValue === teamGender.databaseValue}
                        />
                    );
                })}
                {/* mock error display */}
                {errors?.gender?.name && (
                    <div className="invalid-feedback" style={{ display: 'block' }}>
                        {errors?.gender?.name}
                    </div>
                )}
            </Form.Group>
            <Form.Group controlId="createLeague.division">
                <Form.Label>Category</Form.Label>
                <Form.Control
                    as="select"
                    custom
                    isValid={(touched === true || touched?.category) && !errors?.category}
                    isInvalid={(touched === true || touched?.category) && !!errors?.category}
                    onChange={(event) => {
                        const eventKey = event.target.value;
                        const teamCategory = teamCategories.find((teamCategory) => {
                            return teamCategory.databaseValue === eventKey;
                        });
                        if (teamCategory) {
                            if (teamCategory !== selectedTeamCategory) {
                                setSelectedTeamCategory(teamCategory);
                                setSelectedTeamDivision(teamCategory.divisions[0]);
                                if (selectedGender) {
                                    onUpdateTeamAttributes({
                                        category: teamCategory,
                                        division: teamCategory.divisions[0],
                                        gender: selectedGender,
                                    });
                                } else {
                                    onUpdateTeamAttributes(undefined);
                                }
                            }
                        }
                    }}
                    value={selectedTeamCategory.databaseValue}
                    data-cy="team-category-select"
                >
                    <option value="" disabled hidden>
                        Select Team Category
                    </option>
                    {teamCategories.map((teamCategory) => {
                        return (
                            <option
                                key={teamCategory.databaseValue}
                                value={teamCategory.databaseValue}
                            >
                                {teamCategory.name}
                            </option>
                        );
                    })}
                </Form.Control>
            </Form.Group>
            {selectedTeamCategory && (
                <Form.Group>
                    <Form.Label>Division</Form.Label>
                    <Form.Control
                        custom
                        as="select"
                        isValid={(touched === true || touched?.division) && !errors?.division}
                        isInvalid={(touched === true || touched?.division) && !!errors?.division}
                        onChange={(event) => {
                            const eventKey = event.target.value;
                            const teamDivision = selectedTeamCategory.divisions.find(
                                (teamDivision) => {
                                    return teamDivision.databaseValue === eventKey;
                                }
                            );
                            if (teamDivision) {
                                if (teamDivision !== selectedTeamDivision) {
                                    if (selectedGender) {
                                        onUpdateTeamAttributes({
                                            category: selectedTeamCategory,
                                            division: teamDivision,
                                            gender: selectedGender,
                                        });
                                    } else {
                                        onUpdateTeamAttributes(undefined);
                                    }
                                    setSelectedTeamDivision(teamDivision);
                                }
                            }
                        }}
                        value={selectedTeamDivision.databaseValue}
                        data-cy="team-division-select"
                    >
                        <option value="" disabled hidden>
                            Select Team Division
                        </option>
                        {selectedTeamCategory.divisions.map((teamDivision) => {
                            return (
                                <option
                                    key={teamDivision.databaseValue}
                                    value={teamDivision.databaseValue}
                                >
                                    {teamDivision.name}
                                </option>
                            );
                        })}
                    </Form.Control>
                </Form.Group>
            )}
        </>
    );
}
