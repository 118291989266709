import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { SportProvider } from '../../models/interfaces/SportProvider';

interface Props {
    sportProvider: SportProvider;
    title?: string | ReactNode;
    accessoryView?: ReactNode;
}

const TitleBarContent = styled.div`
    display: flex;
    flex-flow: row;
    align-items: flex-end;
    justify-content: space-between;
    min-height: 70px;

    box-shadow: inset 0px -1px 0px #dee5ef;
    padding: 0px 10px;
    background-color: white;
    width: 100%;
`;

export default function TitleBar({ title, sportProvider, accessoryView }: Props) {
    if (window.location !== window.parent.location) {
        return <></>;
    }
    return (
        <TitleBarContent>
            {typeof title === 'undefined' ? (
                <h4>{sportProvider.name}</h4>
            ) : typeof title === 'string' ? (
                <h4>{title}</h4>
            ) : (
                title
            )}
            {accessoryView}
        </TitleBarContent>
    );
}
