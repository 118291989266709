import React, { ReactElement } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Team } from '../../models/interfaces/Team';

interface Props {
    name: string;
    subtitle ?: string;
    accessoryView?: ReactElement;
}

export default function TeamView({ name, subtitle, accessoryView }: Props) {
    return (
        <Row className="justify-content-between align-items-center px-2">
            {subtitle ? (
                <Col xs="auto">    
                    <Row><h4>{name}</h4></Row>
                    <Row><h5>{subtitle}</h5></Row>
                </Col>) : 
                <Col xs="auto">{name}</Col>
            }
            {accessoryView && (
                <Col xs="auto" className="text-right">
                    {accessoryView}
                </Col>
            )}
        </Row>
    );
}
