import { ListProvider } from '../ListProvider';
import { Match } from '../Match';
import { User, Name } from '../User';
import { Competition } from '../Competition';
import { Resolver } from '../Resolver';
import { RosterEntry, CapNumber, Position } from '../RosterEntry';
import { Team } from '../Team';
import { TeamColor } from '../TeamAttributes';
import { Player } from '../Player';

export function isCompetitionEntry(candidate): candidate is CompetitionEntry {
    return (
        (candidate as CompetitionEntry).competition !== undefined &&
        (candidate as CompetitionEntry).rosterProvider !== undefined
    );
}

export interface CompetitionEntryMetadata {
    name: string;
    abbreviation: string;
    color: TeamColor;
}

export interface CompetitionEntry extends CompetitionEntryMetadata {
    id: string;

    competition: Competition;

    backingTeam?: Team;
    rosterProvider: ListProvider<Resolver<RosterEntry>>;
    matchProvider: ListProvider<Resolver<Match>>;

    adminForUser(user: User): Promise<CompetitionEntryAdmin>;
}

export interface CompetitionEntryAdmin {
    competitionEntry: CompetitionEntry;

    addRosterEntry: (name: Name, capNumber: CapNumber, position: Position) => Promise<RosterEntry>;
    updateRosterEntry: (
        rosterEntry: RosterEntry,
        name: Name,
        capNumber: CapNumber,
        position: Position
    ) => Promise<RosterEntry>;
    setBackingPlayer: (rosterEntry: RosterEntry, player?: Player) => Promise<RosterEntry>;
    archiveRosterEntry: (player: RosterEntry) => Promise<void>;
}
