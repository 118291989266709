import React from 'react';
import TeamView from '../../../components/containers/TeamView';
import { TeamRecord } from '../../../models/interfaces/Competition';

interface TeamRecordViewProps {
    teamRecord: TeamRecord;
}

const TeamRecordView = ({ teamRecord }: TeamRecordViewProps) => {
    return (
        <div>
            <TeamView
                name={teamRecord.team.name}
                accessoryView={
                    <>
                        {teamRecord.wins} - {teamRecord.losses}
                        {teamRecord.draws > 0 ? ` - ${teamRecord.draws}` : null}
                    </>
                }
            />
        </div>
    );
};

export default TeamRecordView;
